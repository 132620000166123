import { JSXElement, type Component } from "solid-js";

type BaseSkelelonProps = {
  class?: string;
  width?: number;
  height?: number;
  delay?: number;
  // children?: JSXElement;
};

export const BaseSkeleton: Component<BaseSkelelonProps> = (props) => {
  return (
    <div
      class="bg-gray-300  animate-pulse duration-1500 rounded-xl p-2 relative"
      classList={{
        [`${props.class}`]: !!props.class,
      }}
      style={{
        width: props.width ? `${props.width}px` : "100%",
        height: props.height ? `${props.height}px` : "auto",
        "animation-delay": props.delay ? `${props.delay}ms` : "0ms"
      }}
    />
  );
};
